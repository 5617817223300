import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FacebookIcon from "../../assets/icono_facebook.webp";
import InstagramIcon from "../../assets/icono_instagram.webp";
import carrito from "../../assets/carrito.png";
import WebIcon from "../../assets/icono_web.webp";
import LinkedinIcon from "../../assets/icono_linkedin.webp";

import BlogDataService from "../../services/services";

import "./Footer.css";

export default function Footer() {
  const [data, setData] = useState({ name: "", email: "" });
  const [open, setOpen] = useState(false);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    setOpen(true);
    e.preventDefault();
    e.target.reset();
    BlogDataService.createNewsletter(data);
  };

  return (
    <footer>
      <div className="coryright">
        <p>
          Copyright ©2024 All rights reserved |
          <br /> Grupo Requiez
        </p>
        <div
          className="tienda-gruporequiez"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          
        </div>
      </div>
      <div className="contact-info">
        <h3>CONTACTO</h3>
        <p className="contact-mail">
          <a
            href="https://gruporequiez.zendesk.com/hc/es-419/requests/new"
            target="_blank"
            rel="noreferrer"
          >
            hola@gruporequiez.com
          </a>
        </p>
        <p className="contact-phone">
          <a href="tel:3338337788">+52 (33) 3833 7788</a>
        </p>
      </div>
      <div className="social">
        <div>
          <a
            href="https://www.facebook.com/Requiez.Oficina/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ marginTop: "0px", cursor: "pointer", height: "40px" }}
              src={FacebookIcon}
              alt="Grupo Requiez - Tienda en Linea"
            />
          </a>
          <a
            href="https://www.instagram.com/requiez.sillas/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ marginTop: "0px", cursor: "pointer", height: "40px" }}
              src={InstagramIcon}
              alt="Grupo Requiez - Tienda en Linea"
            />
          </a>
          <a
            href="https://www.gruporequiez.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ marginTop: "0px", cursor: "pointer", height: "40px" }}
              src={WebIcon}
              alt="Grupo Requiez - Tienda en Linea"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/grupo-requiez/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ marginTop: "0px", cursor: "pointer", height: "40px" }}
              src={LinkedinIcon}
              alt="Grupo Requiez - Tienda en Linea"
            />
          </a>
        </div>
      </div>
      <div className="newsletter">
        <div className="div">
          <p style={{color: "white",}}>Suscríbete al newsletter</p>
          <form onSubmit={handleSubmit}>
            <input
              name="email"
              required
              onChange={handleChange}
              type="email"
              placeholder="e-mail"
              style={{
                marginTop: "0px",
                height: "50px",
                width: "170px",
                border: "none",
                cursor: "pointer",
                borderRadius: "35px 0 0 35px",
                fontWeight: "normal",
                color: "black",
              }}
            />

            <input
              type="submit"
              value="OK"
              className="newsletter-button"
              style={{
                marginTop: "0px",
                marginLeft: "4px",
                height: "50px",
                width: "40px",
                border: "none",
                cursor: "pointer",
                borderRadius: "0 35px 35px 0",
                fontWeight: "normal",
                color: "white",
                backgroundColor: "#1E6057",
              }}
            />

            <Alert
              style={{ display: open ? "" : "none" }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              Registro correcto
            </Alert>
          </form>
        </div>
      </div>
    </footer>
  );
}
