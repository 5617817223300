import React, { useState, useEffect, Fragment } from "react";
import Axios from "axios";
import Slide from "../../components/Slide/Slide";
import BrandDescription from "../../components/Home/BrandDescription/BrandDescription";
import Footer from "../../components/Footer/Footer";

// Images
import requiezDesktop from "../../assets/home_Mesa_de_trabajo.webp";
import labenzeDesktop from "../../assets/labenze-home-02.webp";
import infinitiDesktop from "../../assets/infiniti-home-03.webp";
import okamuraDesktop from "../../assets/okamura-home-04.webp";
import quadrifoglioDesktop from "../../assets/quadrifoglio-home-05.webp";
import interfaceDesktop from "../../assets/interface-home-06.webp";

// Banners
import banner1 from "../../banners/Banner-principal-2024.webp";

import "./Home.css";

export default function Home() {
  const [space, setSpace] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios({
          url: "https://api.expo.gruporequiez.com/api/",
        });

        setSpace(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [setSpace]);

  return (
    <Fragment>
      <Slide
        banner1={banner1}
      />
      <BrandDescription 
        side="right"
        srcImg={requiezDesktop}
        url="/productos/1"
        title="La mejor línea de sillas y sillones para
        oficina."
        description="Reqüiez es líder en mobiliario de
        oficina, colaborando en la generación
        de experiencias, ofreciendo una gama
        de productos dotados de alta calidad,
        diseño y tecnología."
        showButtonAndModal={true}
      />
      <BrandDescription
        side="left"
        srcImg={labenzeDesktop}
        url="/productos/2"
        title="Una línea ideal de mobiliario contract,
        corporativo y residencial."
        description="Esta marca rompe esquemas en
        mobiliario contract, corporativo y
        residencial. <br/><br/> La marca se guía por firmas italianas de
        diseño que complementan el
        interiorismo mexicano."
        showButtonAndModal={true}
      />
      <BrandDescription
        side="right"
        srcImg={infinitiDesktop}
        url="/productos/3"
        title="Diseño italiano multicultural."
        description="Infiniti es una marca joven nacida en
        2008, que ofrece mobiliario diseñado
        para trabajar, relajarse y socializar. <br/><br/> Creatividad, innovación tecnológica,
        metodología del diseño: estos son los
        conceptos clave para entender la
        filosofía de infiniti, que propone
        complementos de decoración creados
        para las necesidades de la vida
        contemporánea."
      />
      <BrandDescription
        side="left"
        srcImg={okamuraDesktop}
        url="/productos/4"
        title="Innovando para un mejor lugar de
        trabajo."
        description="Desde el concepto hasta su resultado
        final, los productos de Okamura
        están llenos de espíritu creativo. Se
        envuelven en los pequeños detalles
        superando el límite del diseño
        convencional. <br/><br/> Una marca de origen japonés con más
        de 60 años en el mercado."
        showButtonAndModal={true}
      />
      <BrandDescription
        side="right"
        srcImg={quadrifoglioDesktop}
        url="/productos/5"
        title="Bienestar y la elegancia en el diseño
        italiano en los ambientes de trabajo."
        description="Quadrifoglio Group fabrica y proyecta
        soluciones de mobiliario completas
        para llevar el bienestar y la elegancia
        del diseño italiano en los ambientes
        de trabajo y contract."
      />
      <BrandDescription
        side="left"
        srcImg={interfaceDesktop}
        url="/productos/7"
        title="Pisos con la menor huella de carbono
        del mercado."
        description="Una marca reconocida con una historia
        de innovación y compromiso con la
        sustentabilidad. Orientados en el
        desempeño del producto y la mejora
        de los espacios, la industria y el mundo. <br/><br/> Proveedor global de pisos comerciales
        como: Alfombras modulares,
        LVT y caucho."
      />

      <Footer />
    </Fragment>
  );
}
