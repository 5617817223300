import React, { Fragment } from "react";
import Banner from "../../components/Banner/Banner";
import Space from "../../components/SpaceV2024/Space";
import SpaceR from "../../components/SpaceRightV2024/SpaceRight";
import Footer from "../../components/Footer/Footer";

// Images
import banner from "../../banners/us.webp";
import product from "../../banners/Nosotros_Bom-bom.webp";
import image from "../../banners/Nosotros.webp";
import image2 from "../../banners/Nosotros_foto-inferior.webp";

// Logos
import infiniti from "../../assets/Infiniti.webp";
import labenze from "../../assets/Labenze.webp";
import okamura from "../../assets/Okamura.webp";
import quadrifoglio from "../../assets/Quadrifoglio.webp";
import requiez from "../../assets/Requiez.webp";
import Interface from "../../assets/interface.webp";

import "./Us.css";

export default function Us() {
  return (
    <Fragment>
      <div className="mw-1700">
        <Banner img={banner} />
        
        <Space
          name="Banner Nosotros"
          image={image}
          info={
            <div className="info11">
              NUESTRA HISTORIA
              <div className="info12">NOS ABRE CAMINO</div>
            </div>
          }
          color="#f2eae7"
          colorText="#1d1d1c"
        />
        
        <section className="us-info">
          <div className="us-text">
            <div className="us-title">
              Nacido en <br />
              <b>
                Guadalajara, Jalisco. <br />
                Desde 1985
              </b>
            </div>
            <p>
              <b>
                En cada producto, el reflejo de la calidad y el compromiso.
              </b>
            </p>
            <p>
              Somos un grupo que brindamos soluciones en mobiliario para espacios de oficina, contract y hospitality.
            </p>
            <p>
              Cada una de nuestras marcas cuenta con una esencia y personalidad propia, ofreciendo una extensa gama de acabados y colores que nos mantienen en tendencia. 
            </p>
          </div>
          <div>
          <img src={product} alt="banco" className="us-info_img" />
          </div>
        </section>
        
        <SpaceR
          name="Banner Nosotros"
          image={image2}
          info={
            <div className="info">
              <p>
                Contamos con exclusividad de socios comerciales internacionales y más de 250 distribuidores en toda la república mexicana.
              </p>
              <p>
                El catálogo de Grupo Requiez es el más extenso del país, con propuestas en tecnología y diseño para corresponder a la necesidad de los distintos usuarios.
              </p>
            </div>
          }
          color="#f2eae7"
          colorText="#1d1d1c"
        />
        
        <section>
          <div className="us-marcas">
            <h2 className="us-marcas_title"><b>NUESTRAS MARCAS</b></h2>
          </div>

          <div className="us-marcas_logos">
            <a href="https://requiez.com" target="_blank" rel="noreferrer">
              <img src={requiez} alt="Requiez Logo" />
            </a>
            <a href="https://labenze.com" target="_blank" rel="noreferrer">
              <img src={labenze} alt="Labenze Logo" />
            </a>
            <a href="https://okamuramexico.com" target="_blank" rel="noreferrer">
              <img src={okamura} alt="Okamura Logo" />
            </a>
            <a
              href="https://infinitidesignmexico.com/es"
              target="_blank"
              rel="noreferrer"
            >
              <img src={infiniti} alt="Infiniti Logo" />
            </a>
            <a
              href="https://quadrifoglio.com.mx"
              target="_blank"
              rel="noreferrer"
            >
              <img src={quadrifoglio} alt="Quadrifoglio Logo" />
            </a>

            <a
              href="https://www.interface.com/LA/es-MX.html?r=1"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Interface} alt="Interface logo" />
            </a>
          </div>
        </section>
        
      </div>
      <Footer />
    </Fragment>
  );
}
