import React, { Fragment } from "react";
import Banner from "../../components/Banner/Banner";
import Footer from "../../components/Footer/Footer";

// Images
import mainBanner from "../../banners/Banner_proceso-creativo.webp"
import banner from "../../assets/STAND-2024_2.webp";

// Galery
import image1 from "../../assets/Stand_lineas.webp";
import image2 from "../../assets/Stand2.webp";
import image4 from "../../assets/Banner-Comex.webp";

import "./Creative.css";

export default function Creative() {
  return (
    <Fragment>
      <div className="mw-1700">
        <Banner img={mainBanner} />
      </div>

      <section className="mw-1700 us-info_creative">
        <img src={image1} alt="banco" className="us-info_img_creative" />
        <div className="us-text_creative">
          <p>
            Como cada año, nuestro objetivo
            es ofrecer una experiencia
            completa a nuestros visitantes,
            además de la presentación de
            nuestros lanzamientos.
          </p>

        </div>
      </section>
      <section className="mw-1700 us-info_creative">
        <div className="us-text_creative">
          <h2><b>Concepto</b></h2>
          <p>
            En Grupo Requiez, trabajamos constantemente para que
            nuestros procesos sean cada vez más verdes, desde la
            producción, materiales, hasta cada pieza de mobiliario;
            incluso, este mismo stand, reduciendo el impacto de
            nuestra huella de carbono.
          </p>
        </div>
        <img src={image2} alt="banco" className="us-info_img_creative" />
      </section>

      <div className="mw-1700">
        <Banner img={banner} />
      </div>

      <div className="mw-1700 comexBanner" >
        <img src={image4} alt="banco" className="us-info_img_creative3" />


        <a
          href="https://www.comex.com.mx/comextrends?utm_campaign=_comex_trends-2023&utm_source=QR&utm_medium=%20content&utm_content=trends-2023-qr&utm_term=landing-trends-2023"
          target="_blank"
        >
          <button className="comexButton">
            <strong>TIENDA COMEX</strong>
          </button>
        </a>
      </div>

      <Footer />
    </Fragment>
  );
}
