import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import "./BrandDescription.css";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  p: 4,
};

const productSrcMap = {
  1: "https://dapper-kulfi-96f30a.netlify.app/",
  2: "https://dapper-kulfi-96f30a.netlify.app/requiez.html",
  4: "https://dapper-kulfi-96f30a.netlify.app/okamura.html",
  // Agrega más según sea necesario
};

export default function BrandDescription({ srcImg, title, description, side, url, showButtonAndModal }) {

  const divFirst = `${side === 'right' ? 'div-a' : 'div-b'}`;
  const divSecond = `home-brand-section-description-info ${side === 'right' ? 'div-b' : 'div-a'}`;

  const [open, setOpen] = useState(false);
  const [productId, setProductId] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const productIdFromUrl = url.split("/").pop();
    setProductId(productIdFromUrl);
  }, [url]);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div className="mw-1700">
      <div className={`home-brand-section-description ${side}`}>
        <div className={divFirst}>
          <a href={url}>
            <img className="home-brand-section-description-image" src={srcImg} alt={title} />
          </a>
        </div>
        <div className={divSecond}>
          <div>
            <p className="home-brand-section-description-title">{title}</p>
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
            {/* Mostrar el botón y el modal solo si showButtonAndModal es verdadero */}
            {showButtonAndModal && (
              <div className="pt-3">
                <button onClick={handleOpen} className="my-button">Observar Modelos 3D</button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    {loading && (
                      <div className="d-flex justify-content-center align-items-center">
                        <div class="spinner-grow text-secondary" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-secondary" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-secondary" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-secondary" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-secondary" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-secondary" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )}
                    <iframe
                      title={`Product ${productId}`}
                      src={productSrcMap[productId]}
                      width="100%"
                      height="100%"
                      onLoad={handleLoad}
                      style={{ display: loading ? 'none' : 'block' }}
                    ></iframe>
                  </Box>
                </Modal>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
