import React, { Fragment } from "react";

import ImageGallery from "react-image-gallery";
import './SliderImg.css'

export default function SliderImg({ imgs }) {
  if (imgs.length) {
    return (
      <Fragment>
        <ImageGallery items={imgs} />
      </Fragment>
    );
  }
  return '';
}
