import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/logo.webp";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import qr from "../../assets/qr.webp";
import "./Header.css";


function App() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
<Navbar expand="md" style={{ backgroundColor: "#1E6057"}}>
  <Container fluid className="containerStyle" >
    <Navbar.Brand>
      <a href="https://gruporequiez.com" target="_blank" rel="noreferrer">
        <img className="navLogo"
          src={logo}
          alt="Logo Grupo Requiez"

        />
      </a>
    </Navbar.Brand>
    <div className="navbar-toggle-opened">
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
    </div>
    <Navbar.Collapse id="basic-navbar-nav" >
        <Nav id="navTitle" className="d-flex justify-content-end">
        <Link className="linkHeader" to="/">
          INICIO
        </Link>
        <Link className="linkHeader" to="/nosotros">
          NOSOTROS
        </Link>
        <Link className="linkHeader" id="creativePro" to="/creativo">
          PROCESO CREATIVO
        </Link>
        <Link className="linkHeader" to="#" onClick={() => setModalIsOpen(true)}>
          DESCARGAS
        </Link>
        <Modal className="customStyle"            
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={qr}
              alt="In Popup"
              style={{ width: "70%", height: "70%" }}
            />
            <p style={{ marginTop: "20px", textAlign: "center" }}>¡Escanéame!</p>
          </div>
        </Modal>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
  );
}

export default App;
