import React from "react";
import { Link } from "react-router-dom";

import "./Space.css";

export default function Space({ id, name, image, info, color, colorText }) {
  return (
    <div className="space2024-container">
      {id ? (
        <Link to={`/productos/${id}`}>
          <div className="space-image">
            <img src={image} alt={name} />
          </div>
        </Link>
      ) : (
        <div className="space-image">
          <img src={image} alt={name} />
        </div>
      )}
      <div className="space2024-text" style={{ backgroundColor: color }}>
        <p
          className="container-pgh"
          style={{ color: colorText }}
        >
          {info}
        </p>
      </div>
    </div>
  );
}
