import React, { Fragment, useEffect, useState } from "react";
import Axios from "axios";
import Banner from "../../components/Banner/Banner";
import banner from "../../banners/descargas.webp";
import BusinessCard from "../../components/BusinessCard/BusinessCard";
import Footer from "../../components/Footer/Footer";

// Catalodos
import infiniti from "../../assets/Catalogo_Infiniti.webp";
import labenze from "../../assets/Catalogo_Labenze.webp";
import okamura from "../../assets/Catalogo_Okamura.webp";
import quadrifoglio from "../../assets/Catalogo_Quadrifoglio.webp";
import requiez from "../../assets/Catalogo_Requiez.webp";
import hospitality from "../../assets/Catalogo_Hospitality.webp";
import magazine from "../../assets/Catalogo_Magazine.webp";

import "./Descargas.css";

export default function Webinars() {
  const [webinar, setWebinar] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios({
          url: "https://api.expo.gruporequiez.com/api/webinars/",
        });

        setWebinar(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [setWebinar]);

  return (
    <Fragment>
      <Banner img={banner} />
      <section>
        <div className="us-marcas"></div>

        <div className="us-marcas_logos">
          <a href="https://expo.gruporequiez.com/files/Requiez.pdf" target="_blank" rel="noreferrer">
            <img src={requiez} alt="Catalogo Requiez" title="DESCARGAR" />
          </a>
          <a href="https://expo.gruporequiez.com/files/Infiniti.pdf" target="_blank" rel="noreferrer">
            <img src={infiniti} alt="Catalogo Infiniti" />
          </a>
          <a href="https://expo.gruporequiez.com/files/Labenze.pdf" target="_blank" rel="noreferrer">
            <img src={labenze} alt="Catalogo Labenze" />
          </a>
          <a
            href="https://expo.gruporequiez.com/files/Quadrifoglio.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src={quadrifoglio} alt="Catalogo Quadrifoglio" />
          </a>
          <a
            href="https://expo.gruporequiez.com/files/Okamura.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src={okamura} alt="Catalogo Okamura" />
          </a>

          <a
            href="https://expo.gruporequiez.com/files/Hospitality.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src={hospitality} alt="Catalogo Hospitality" />
          </a>
          <a
            href="https://expo.gruporequiez.com/files/Magazine.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src={magazine} alt="Catalogo Hospitality" />
          </a>
          
        </div>
      </section>
      {/*
          <div className='container-webinar'>
            {
                webinar.map(({ id, name, theme, date, image }) => (
                    <BusinessCard 
                        key={ id }
                        name = { name }
                        theme = { theme }
                        date = { date }
                        image = { image }
                    />
                ))
            }
          </div>*/}
      <Footer />
    </Fragment>
  );
}
